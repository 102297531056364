import * as moment from 'moment';

//@dynamic
export class DateUtils {
  public static UTC_LIKE_TIMEZONE = 'Africa/Accra';
  public static DATE_TIME_FORMAT_WITHOUT_TZ = 'yyyy-MM-DD HH:mm:ss';
  public static dateRegex =
    /^(?:(\d{2})\/(\d{2})\/(\d{4})|(\d{2})\/(\d{2})|(\d{2})|(\d{2})\/(\d{4}))$/;

  public static getCurrentUTCDayMoment(): moment.Moment {
    return moment().tz(DateUtils.UTC_LIKE_TIMEZONE).startOf('day');
  }
  public static getCurrentUTCDayDate(): Date {
    return DateUtils.getCurrentUTCDayMoment().toDate();
  }

  public static getCurrentTenantDayMoment(): moment.Moment {
    let tenantTimeZone = JSON.parse(localStorage.getItem('tenantTimeZone'));
    const timeDiff = moment().tz(tenantTimeZone).utcOffset() / 60;
    return moment()
      .tz(DateUtils.UTC_LIKE_TIMEZONE)
      .add(timeDiff, 'hours')
      .startOf('day');
  }
  public static getCurrentTenantDayDate(): Date {
    return DateUtils.getCurrentTenantDayMoment().toDate();
  }

  public static getCurrentTenantDayDateWithFormat(): moment.Moment {
    return moment(
      DateUtils.getCurrentTenantDayMoment().toDate(),
      'DD/MM/YYYY',
    );
  }

  public static getCurrentTimestampMoment(): moment.Moment {
    return moment();
  }
  public static getCurrentTimestampDate(): Date {
    return new Date();
  }
  public static getCurrentTimestampDateFormat(): moment.Moment {
    return moment(new Date());
  }
  public static filterByDate(
    startDate: Date | string,
    endDate: Date | string,
    date: Date | string,
    startTimeWithDate?: Date,
    endTimeWithDate?: Date,
  ): boolean {
    if (!date) return true;
    if (typeof startDate === 'string') startDate = new Date(startDate);
    if (typeof endDate === 'string') endDate = new Date(endDate);
    if (typeof date === 'string') date = new Date(date);
    if (startTimeWithDate && endTimeWithDate)
      return (
        (startDate <= startTimeWithDate && !endDate) ||
        (startDate <= startTimeWithDate && endTimeWithDate <= endDate)
      );
    return (
      (startDate <= date && !endDate) || (startDate <= date && date <= endDate)
    );
  }

  public static getTenantTimeZone() {
    return JSON.parse(localStorage.getItem('tenantTimeZone'));
  }

  public static updateTimeToTenantTimeZone(
    date: moment.Moment,
    time: string,
  ): moment.Moment {
    const [startHour, startMinute] = time.split(':');

    return moment(date)
      .tz(DateUtils.getTenantTimeZone())
      .set({
        hour: parseInt(startHour),
        minute: parseInt(startMinute),
      });
  }

  public static isValidDateFormat(dateString: string): boolean {
    return this.dateRegex.test(dateString);
  }

  public static isValid(dateString: string, dateFormat): boolean {
    return moment(dateString, dateFormat, true).isValid();
  }

  public static isValidDateTime(dateString: string, dateFormat: string): boolean {
    const momentDate = moment(dateString, dateFormat, true);
    if (!momentDate.isValid()) {
      return false;
    }

    const [datePart, timePart] = dateString.split(' ');
    if (!timePart || !/^\d{2}:\d{2}$/.test(timePart)) {
      return false;
    }

    return true;
  }

  public static isValidDate(dateString: string): boolean {
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (!regex.test(dateString)) {
      return false;
    }
    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  }

  public static format(dateString: string, dateFormat) {
    return moment(dateString, dateFormat);
  }

  public static transformDateForSearch(dateString: string): string {
    return dateString?.split('/')?.reverse()?.join('.');
  }


  public static changeDateToBrowserTimezone(momentDate) {
    // Date picker is using CustomMomentDateAdapter and it is converting the date wrong way, we will use this function to fix it
    if (!momentDate) {
      return null;
    }

    if (moment.isMoment(momentDate)) {
      const temp = momentDate.format(this.DATE_TIME_FORMAT_WITHOUT_TZ);
      return new Date(temp).toString();
    }

    return momentDate;
  }

  public static updateDateWithCurrentTime(dateString: string) {
    const inputDate = new Date(dateString);
    const now = new Date();
    inputDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
    return inputDate;
  }

  public static parseStringToDate(dateValue: string): Date {
    const [day, month, year] = dateValue.split('/').map(Number);
    return new Date(year, month - 1, day);
  }

  public static convertMomentToDate(momentDate: moment.Moment): Date {
    const momentString = momentDate.toISOString().split('T')[0];
    const [year, month, day] = momentString.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  static   generateYears(startYear: number = 2002, endYear?: number) {
    const currentYear = DateUtils.getCurrentTenantDayDate().getFullYear();
    const finalEndYear = endYear ?? currentYear;

    return Array.from(
      { length: finalEndYear - startYear + 1 },
      (_, index) => {
        const year = finalEndYear - index;
        return { label: year, value: year };
      }
    );
  }
}
